import {
  SlTag
} from "./chunk.DUWACTPH.js";

// src/components/tag/tag.ts
var tag_default = SlTag;
SlTag.define("sl-tag");

export {
  tag_default
};
