import {
  SlAlert
} from "./chunk.57YTXU6H.js";

// src/components/alert/alert.ts
var alert_default = SlAlert;
SlAlert.define("sl-alert");

export {
  alert_default
};
