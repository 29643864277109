import {
  SlCheckbox
} from "./chunk.RENYXUSO.js";

// src/components/checkbox/checkbox.ts
var checkbox_default = SlCheckbox;
SlCheckbox.define("sl-checkbox");

export {
  checkbox_default
};
