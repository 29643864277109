import {
  SlAnimation
} from "./chunk.6UG7QZL4.js";

// src/components/animation/animation.ts
var animation_default = SlAnimation;
SlAnimation.define("sl-animation");

export {
  animation_default
};
