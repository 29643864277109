import {
  SlSplitPanel
} from "./chunk.X5VXI5JU.js";

// src/components/split-panel/split-panel.ts
var split_panel_default = SlSplitPanel;
SlSplitPanel.define("sl-split-panel");

export {
  split_panel_default
};
