import {
  SlTooltip
} from "./chunk.USUKAGCW.js";

// src/components/tooltip/tooltip.ts
var tooltip_default = SlTooltip;
SlTooltip.define("sl-tooltip");

export {
  tooltip_default
};
