import {
  SlFormatBytes
} from "./chunk.XC2IEFN4.js";

// src/components/format-bytes/format-bytes.ts
var format_bytes_default = SlFormatBytes;
SlFormatBytes.define("sl-format-bytes");

export {
  format_bytes_default
};
