import {
  SlMenuLabel
} from "./chunk.4I3MPDQ4.js";

// src/components/menu-label/menu-label.ts
var menu_label_default = SlMenuLabel;
SlMenuLabel.define("sl-menu-label");

export {
  menu_label_default
};
