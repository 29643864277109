import {
  SlIcon
} from "./chunk.I6OQK3N3.js";

// src/components/icon/icon.ts
var icon_default = SlIcon;
SlIcon.define("sl-icon");

export {
  icon_default
};
