import {
  SlCopyButton
} from "./chunk.Z7MMA56O.js";

// src/components/copy-button/copy-button.ts
var copy_button_default = SlCopyButton;
SlCopyButton.define("sl-copy-button");

export {
  copy_button_default
};
