import {
  SlBreadcrumb
} from "./chunk.EODJMMOD.js";

// src/components/breadcrumb/breadcrumb.ts
var breadcrumb_default = SlBreadcrumb;
SlBreadcrumb.define("sl-breadcrumb");

export {
  breadcrumb_default
};
