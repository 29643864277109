import {
  SlPopup
} from "./chunk.UFYD5JS6.js";

// src/components/popup/popup.ts
var popup_default = SlPopup;
SlPopup.define("sl-popup");

export {
  popup_default
};
