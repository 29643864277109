import {
  SlFormatNumber
} from "./chunk.TW4BK7EB.js";

// src/components/format-number/format-number.ts
var format_number_default = SlFormatNumber;
SlFormatNumber.define("sl-format-number");

export {
  format_number_default
};
