import {
  SlRelativeTime
} from "./chunk.B5FRIB5F.js";

// src/components/relative-time/relative-time.ts
var relative_time_default = SlRelativeTime;
SlRelativeTime.define("sl-relative-time");

export {
  relative_time_default
};
