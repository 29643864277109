import {
  SlMenuItem
} from "./chunk.NEKKXV7B.js";

// src/components/menu-item/menu-item.ts
var menu_item_default = SlMenuItem;
SlMenuItem.define("sl-menu-item");

export {
  menu_item_default
};
