import {
  SlRating
} from "./chunk.PHEMZU7K.js";

// src/components/rating/rating.ts
var rating_default = SlRating;
SlRating.define("sl-rating");

export {
  rating_default
};
