import {
  SlImageComparer
} from "./chunk.IYBYHSNG.js";

// src/components/image-comparer/image-comparer.ts
var image_comparer_default = SlImageComparer;
SlImageComparer.define("sl-image-comparer");

export {
  image_comparer_default
};
