import {
  SlDetails
} from "./chunk.QWPWJGDK.js";

// src/components/details/details.ts
var details_default = SlDetails;
SlDetails.define("sl-details");

export {
  details_default
};
